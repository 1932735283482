import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { ModalService } from '../../../services/modal.service';
import { WithdrawalService } from '../../../services/withdrawal.service';
import { BalanceService } from '../../../services/balance.service';
import { Button } from '../../../interfaces/button.interfaces';
import { GaiminResponse } from '../../../interfaces/http.interfaces';
import * as WAValidator from 'multicoin-address-validator';
import { AsyncPipe, DecimalPipe, NgIf } from '@angular/common';
import { ButtonComponent } from '../../button/button.component';
import { ClickedOutsideDirective } from '../../../directives/clicked-outside.directive';
import { ChainData, WithdrawalOption, WithdrawalOptionResponse } from '../../../interfaces/withdrawal.interfaces';
import { CHAIN, WITHDRAW_CURRENCY } from '../../../enums/withdrawal.enums';

@Component({
  selector: 'app-withdraw-modal',
  templateUrl: './withdraw-modal.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, ButtonComponent, AsyncPipe, DecimalPipe, ClickedOutsideDirective],
  styleUrls: ['./withdraw-modal.component.scss']
})
export class WithdrawModalComponent implements OnInit, OnDestroy {
  readonly withdrawButton: Button = { name: 'Transfer', type: 'submit' };
  readonly cancelButton: Button = { name: 'Cancel', classMod: 'secondary' };
  isAutofillModalActive: boolean = false;
  @AutoUnsubscribe()
  walletDataSubs: Subscription | undefined;
  @AutoUnsubscribe()
  isAuthenticatedSubs: Subscription | undefined;
  @AutoUnsubscribe()
  balanceSubscription: Subscription | undefined;
  @AutoUnsubscribe()
  minAmountSubscription: Subscription | undefined;
  walletData: string[] = [];

  walletLength = 42;

  availableBalance$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  minAmountToWithdraw$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  form: UntypedFormGroup = new UntypedFormGroup({
    amount: new UntypedFormControl('', this.setAmountValidators()),
    wallet: new UntypedFormControl('', [Validators.required, this.validateWalletsInput as any])
  });

  withdrawalOptions: WithdrawalOption[] = [];
  activeCurrency: WITHDRAW_CURRENCY = WITHDRAW_CURRENCY.GMRX;
  errorMessage: string = '';
  chainData: ChainData[] = [];
  defaultChain: CHAIN = CHAIN.BNB_CHAIN;

  constructor(
    private modalService: ModalService,
    public withdrawalService: WithdrawalService,
    // public autoFillService: AutoFillService,
    public balanceService: BalanceService // public additionalService: AdditionalService, // public venlyService: VenlyService
  ) {}

  validateWalletsInput(c: UntypedFormControl): any {
    const HEDERA_CHECKSUM = /^(0|(?:[1-9]\d*))\.(0|(?:[1-9]\d*))\.(0|(?:[1-9]\d*))-[a-z]{5}$/;
    const HEDERA_NO_CHECKSUM = /^(0|(?:[1-9]\d*))\.(0|(?:[1-9]\d*))\.(0|(?:[1-9]\d*))$/;
    const HEDERA_TWO_DOTS = /^(?=.*?\.\d+\.\d+)(?!.*-$).*$/;
    const DEFAULT_WALLET = /^0x[0-9a-fA-F]{40}$/;

    const errorValidation = {
      validateInput: {
        valid: false
      }
    };

    if (HEDERA_NO_CHECKSUM.test(c.value)) {
      return null;
    } else if (DEFAULT_WALLET.test(c.value)) {
      return null;
    } else if (HEDERA_CHECKSUM.test(c.value)) {
      return errorValidation;
    } else if (!HEDERA_TWO_DOTS.test(c.value)) {
      return errorValidation;
    } else {
      return errorValidation;
    }
  }

  ngOnInit() {
    // this.autoFillService.getWalletAutofillOnChange('');
    // this.walletDataSubs = this.autoFillService.walletData$.subscribe({
    //   next: (autofillData: string[]) => {
    //     this.walletData = autofillData;
    //   }
    // });
    // this.initWithdrawalOptions();
    // this.additionalService.initChains(this.form, this.defaultChain, this.chainData);
  }

  private initWithdrawalOptions() {
    this.withdrawalService.getWithdrawalOptionsRequest().subscribe({
      next: (res: GaiminResponse<WithdrawalOptionResponse>) => {
        // this.loaderService.showLoader();
        if (res.success && res.data) {
          this.withdrawalOptions = res.data.options;
          const activeWithdrawalOption = this.withdrawalOptions.find((el) => el.currency === WITHDRAW_CURRENCY.GMRX)!;
          this.activeCurrency = activeWithdrawalOption.currency;
          this.onSetCurrency(activeWithdrawalOption);
        } else {
          this.errorMessage = res.error!.description;
        }
      },
      complete: () => {
        // this.loaderService.hideLoader();
      }
    });
  }

  private setAmountValidators() {
    return [
      Validators.required,
      Validators.pattern(/^[0-9]+(\.[0-9]{1,2})*$/),
      Validators.min(this.minAmountToWithdraw$.getValue()),
      Validators.max(this.availableBalance$.getValue())
    ];
  }

  onChangeAmount(event: any) {
    const amount = this.normalizeAmount(event.data, event.target.value, 2).replaceAll(',', '');
    // this.additionalService.previousValue$.next(amount);
    this.form.get('amount')!.setValue(amount);
  }

  normalizeAmount(keyPressed: any, amount: any, countNumbersAfterDot: any) {
    const validValues = '1234567890.';
    const beforeAndAfterDot = amount.split('.');

    // if (beforeAndAfterDot.length > 2) {
    //   return this.previousValue$.getValue();
    // }

    if (validValues.includes(keyPressed)) {
      if (beforeAndAfterDot[0].startsWith('0') && beforeAndAfterDot[0].length > 1) {
        return amount.slice(1);
      }

      return !(beforeAndAfterDot.length > 2 || beforeAndAfterDot[1]?.length > countNumbersAfterDot)
        ? amount
        : amount.slice(0, -1);
    }
    return amount.split(keyPressed).join('');
  }

  onSetCurrency(withdrawOption: WithdrawalOption) {
    this.availableBalance$.next(this.balanceService.userPaidBalance$.value);
    this.minAmountToWithdraw$.next(withdrawOption.minimumAmount);
    this.form.controls['amount'].setValidators(this.setAmountValidators());
    this.form.controls['amount'].updateValueAndValidity();
  }

  openAutofillModal() {
    this.isAutofillModalActive = true;
  }

  onChangeWallet(event: any) {
    const wallet = event.target.value.replace(/\s/g, '');
    this.form.get('wallet')!.setValue(wallet);
  }

  hideAutofillModal() {
    this.isAutofillModalActive = false;
  }

  setWalletAddress(wallet: string) {
    this.form.get('wallet')!.setValue(wallet);
    // this.autoFillService.getWalletAutofillOnChange('');
    this.hideAutofillModal();
  }

  onCancel() {
    this.modalService.close();
    setTimeout(() => {
      this.errorMessage = '';
    }, this.modalService.closeModalDurationMS);
  }

  onSubmit() {
    if (this.form.valid) {
      const { amount, wallet } = this.form.value;
      const currency = this.activeCurrency;
      if (this.availableBalance$.getValue() < amount) {
        // this.toastrService.error(
        //   `Your balance is ${
        //     this.activeCurrency
        //   } ${this.availableBalance$.getValue()}, please reduce the amount to withdraw`
        // );
        return;
      }
      const validateWallet = WAValidator.validate(wallet, 'tusd', 'BTC');
      if (validateWallet) {
        this.form.reset();
        this.withdrawalService.makeWithdrawal({ amount, currency, walletAddress: wallet }).subscribe({
          next: (response) => {
            console.log('Transfer response: ' + response + '\n' + JSON.stringify(response));
            // this.toastrService.success('Please confirm transfer in your email.');
            this.balanceService.updateUserPaidBalance();
            this.withdrawalService.getUserWithdrawals(1);
          },
          error: (error) => {
            console.error('Transfer error: ' + error + '\n ' + JSON.stringify(error));
            let errorMessage = 'Sorry, something went wrong.';

            if (error !== undefined && error.error !== undefined) {
              errorMessage = 'Error: ' + error.error;
            }
            // this.toastrService.error(errorMessage);
          }
        });
        this.onCancel();
      } else {
        // this.toastrService.error('Error: your wallet address is incorrect');
      }
    }
  }

  ngOnDestroy(): void {
    // this.additionalService.previousValue$.next('');
  }
}
