import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { GaiminResponse } from '../interfaces/http.interfaces';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { BalancePaid } from '../interfaces/balance.interfaces';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {
  private readonly BALANCE_UPDATE_TIME = 1000 * 30;

  userPaidBalance$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.isLoggedIn$.subscribe({
      next: (isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.updateUserPaidBalance();
          interval(this.BALANCE_UPDATE_TIME).subscribe({
            next: () => {
              this.updateUserPaidBalance();
            }
          });
        }
      }
    });
  }

  updateUserPaidBalance() {
    this.getUserPaidBalanceRequest().subscribe({
      next: (userPaidBalanceResponse) => {
        if (userPaidBalanceResponse.data && userPaidBalanceResponse.success) {
          this.userPaidBalance$.next(userPaidBalanceResponse.data.paid.GMRX);
        }
      }
    });
  }

  private getUserPaidBalanceRequest(): Observable<GaiminResponse<BalancePaid>> {
    return this.http.get<GaiminResponse<BalancePaid>>(`${environment.gaiminApi}/users/me/balance/paid`, {
      headers: this.authService.authHeaders()
    });
  }
}
