<div class="withdrawal">
  <div class="withdrawal-balance">
    <h3 class="withdrawal-balance__title">Your Balance</h3>

    <div class="withdrawal-balance__item">
      <div class="withdrawal-balance__item_value">{{ balanceService.userPaidBalance$ | async | number : '1.11-11' }}
        <span>GMRX</span></div>
      <p class="withdrawal-balance__item_description">Platform Balance</p>
    </div>


  </div>


  <app-button
    [title]="
          isPending
            ? 'Please finish the PENDING transfer before creating a new one.'
            : isWithdrawDisabled
            ? 'Transfer is temporarily disabled'
            : null
        "
    [buttonData]="button"
    (click)="onClick()"
    [disabled]="isPending || isWithdrawDisabled"
    [attr.disabled]="isPending || isWithdrawDisabled"></app-button>

</div>
