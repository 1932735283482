import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OktaAuthModule } from '@okta/okta-angular';
import { NgModule } from '@angular/core';
import OktaAuth from '@okta/okta-auth-js';
import oktaConfig from './app.config';
import { AppRoutingModule } from './app.routes';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { NgxSmartModalModule } from 'ngx-smart-modal';

const oktaAuth = new OktaAuth(oktaConfig.oidc);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    AppRoutingModule,
    NgxSmartModalModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }]
})
export class AppModule {}
