import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { DeviceData } from '../../interfaces/dashboard.interfaces';
import { GaiminResponse } from '../../interfaces/http.interfaces';
import { AuthService } from '../../services/auth.service';
import { DashboardService } from '../../services/dashboard.service';
import { DatePipe, DecimalPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { DeviceInfo } from '../../interfaces/devices.interfaces';
import {ModalService} from "../../services/modal.service";
import {MODALS} from "../../enums/modal.enums";
import {ReassignDeviceModalComponent} from "../modals/reassign-device-modal/reassign-device-modal.component";

@Component({
  selector: 'app-devices-table',
  templateUrl: './devices-table.component.html',
  standalone: true,
  imports: [NgIf, NgForOf, NgClass, ButtonComponent, DatePipe, DecimalPipe],
  styleUrls: ['./devices-table.component.scss']
})
export class DevicesTableComponent implements OnInit {
  currentDevice: DeviceInfo = {} as DeviceInfo;
  isLoggedIn: boolean = true;

  @AutoUnsubscribe() getDeviceInfoSubs: Subscription | undefined;
  @AutoUnsubscribe() isLoggedInSubs: Subscription | undefined;

  @Input() devices: DeviceData[] = [];
  @Input() showHidden: boolean = true;

  constructor(private authService: AuthService, private dashboardService: DashboardService, private modalService:ModalService) {}

  ngOnInit() {
    this.getDeviceInfoSubs = this.dashboardService.getDeviceInfo().subscribe({
      next: (device: GaiminResponse<DeviceInfo>) => (this.currentDevice = device.data!)
    });

    this.isLoggedInSubs = this.authService.isLoggedIn$.subscribe({
      next: (isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn)
    });
  }

  isAllDevicesHidden(devices: DeviceData[]) {
    return devices.every((device: DeviceData) => device.device.hidden);
  }

  handleDeviceVisible(device: DeviceData) {
    this.dashboardService.handleDeviceVisible(device);
  }

  openReassignModal(device: DeviceData) {
    this.modalService.bufferData = device;
    this.modalService.create(MODALS.REASSIGN_DEVICE, ReassignDeviceModalComponent).open();
  }
}
