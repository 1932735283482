<div class="cancel-withdraw" *ngIf="isPending">
  <div class="cancel-withdraw__text">
    Pending:
    <span
      class="cancel-withdraw__text-amount">{{ withdrawalService.pendingWithdrawalData.amount }} {{ withdrawalService.pendingWithdrawalData.currency }}</span>
  </div>
  <app-button
    *ngIf="showCancelButton"
    [buttonData]="button"
    class="cancel-withdraw__button"
    (click)="onClick()">
  </app-button>
</div>
