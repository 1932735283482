<div class="custom-checkbox-dropdown" [ngClass]="{ active: isDropdownOpen }"
     appClickedOutside (clickOutside)="closeDropdown()">
  <p (click)="toggleDropdown()">
    <span class="text" [ngClass]="{ selected: isSelected }">
      {{ name }}
    </span>
    <span class="icon icon-Arrow-Bottom"></span>
  </p>
  <ul>
    <li *ngFor="let option of options">
      <label>
        <span class="text">{{ option.name }}</span>
        <input
          type="checkbox"
          [value]="option.id"
          [checked]="option.isChecked"
          (change)="onCheckboxChange($event)"/>
        <span class="checkmark icon-Checked"></span>
      </label>
    </li>
  </ul>
</div>
