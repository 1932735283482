import { Injectable, ViewContainerRef } from '@angular/core';
import { INgxSmartModalOptions, NgxSmartModalService } from 'ngx-smart-modal';
import { MODALS } from '../enums/modal.enums';
import {Content} from "ngx-smart-modal/lib/services/ngx-smart-modal.service";

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private parentContainer: ViewContainerRef | any;
  closeModalDurationMS = 500; // Modal window closes within 500ms (from documentation)
  bufferData: any;
  modalTitleData: string | null = '';
  errorApi: any;

  constructor(private ngxSmartModalService: NgxSmartModalService) {}

  set modalParentComponentRefference(compRef: ViewContainerRef) {
    this.parentContainer = compRef;
  }

  create(modalId: MODALS, cmp: Content<unknown>, options: INgxSmartModalOptions = {}) {
    return this.ngxSmartModalService.create(modalId, cmp, this.parentContainer, options);
  }

  getModal(modalId: MODALS) {
    return this.ngxSmartModalService.getModal(modalId);
  }

  resetData() {
    setTimeout(() => {
      this.modalTitleData = null;
      this.bufferData = null;
      this.errorApi = null;
    }, this.closeModalDurationMS);
  }

  close() {
    this.ngxSmartModalService.closeLatestModal();
    this.resetData();
  }

  closeById(modalId: MODALS) {
    try {
      this.ngxSmartModalService.close(modalId);
      this.resetData();
    } catch (e) {
      console.log('closeById e:', e);
    }
  }

  closeAll() {
    this.ngxSmartModalService.closeAll();
    this.resetData();
  }

  get modalIds(): typeof MODALS {
    return MODALS;
  }
}
