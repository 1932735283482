<div class="withdraw-modal" (submit)="onSubmit()">
  <h3 class="withdraw-modal__title">Balance Transfer</h3>
  <div
    class="withdraw-modal__content"
    *ngIf="balanceService.userPaidBalance$.value >= (minAmountToWithdraw$ | async); else notEnoughBalance">
    <p>
      Your available balance to transfer is {{ availableBalance$ | async | number : '1.2-2' }}
      {{ activeCurrency }}
    </p>
    <form [formGroup]="form" *ngIf="!errorMessage; else error">
      <div class="withdraw-modal__field-group">
        <input #amount formControlName="amount" placeholder="Transfer amount" (input)="onChangeAmount($event)" />
        <div class="withdraw-modal__error" *ngIf="form.get('amount').invalid && form.get('amount').touched">
          <div *ngIf="form.get('amount').hasError('required')">Amount should be present</div>
          <div *ngIf="form.get('amount').hasError('min')">Minimum amount is {{ minAmountToWithdraw$ | async }}</div>
          <div *ngIf="form.get('amount').hasError('max')">Withdrawal amount exceeds available balance</div>
          <div *ngIf="form.get('amount').hasError('pattern')">
            Amount should be a number with a maximum of 2 digits after the dot
          </div>
        </div>
      </div>
<!--      <div class="withdraw-modal__field-group">-->
<!--&lt;!&ndash;        <app-custom-dropdown&ndash;&gt;-->
<!--&lt;!&ndash;          [options]="chainData"&ndash;&gt;-->
<!--&lt;!&ndash;          [optionName]="'chainTitle'"&ndash;&gt;-->
<!--&lt;!&ndash;          [currentValue]="venlyService.chainList['BSC']"></app-custom-dropdown>&ndash;&gt;-->
<!--        <input id="currency" type="hidden" />-->
<!--      </div>-->
      <div class="withdraw-modal__field-group" appClickedOutside (clickOutside)="hideAutofillModal()">
        <ul class="autofill" *ngIf="isAutofillModalActive && walletData?.length > 0">
          <li class="autofill-item" *ngFor="let wallet of walletData" (click)="setWalletAddress(wallet)">
            <span class="autofill-item__text">{{ wallet }}</span>
          </li>
        </ul>
        <input
          #wallet
          formControlName="wallet"
          placeholder="Wallet address"
          type="text"
          (focusin)="openAutofillModal()"
          (input)="onChangeWallet($event)" />
<!--          (ngModelChange)="autoFillService.getWalletAutofillOnChange($event)"-->
        <div class="withdraw-modal__error" *ngIf="form.get('wallet').invalid && form.get('wallet').touched">
          <div *ngIf="form.get('wallet').hasError('validateInput') && !form.get('wallet').hasError('required')">
            Invalid wallet address
          </div>
          <div *ngIf="form.get('wallet').hasError('required')">Wallet should be present</div>
        </div>
      </div>

      <div class="withdraw-modal__buttons">
        <app-button
          class="withdraw-modal__button"
          [buttonData]="withdrawButton"
          [attr.disabled]="form.invalid || amount.value < (minAmountToWithdraw$ | async)"></app-button>
        <app-button class="withdraw-modal__button" [buttonData]="cancelButton" (click)="onCancel()"></app-button>
      </div>
    </form>
  </div>
  <ng-template #error>
    <p>
      {{ errorMessage }}
    </p>
    <app-button class="withdraw-modal__button" [buttonData]="cancelButton" (click)="onCancel()"></app-button>
  </ng-template>
  <ng-template #notEnoughBalance>
    <p>
      You do not have enough balance to transfer. Minimum amount to transfer is {{ minAmountToWithdraw$ | async }} GMRX.
    </p>
    <p>Your current balance is {{ balanceService.userPaidBalance$ | async | number : '1.2-2' }} GMRX.</p>
    <app-button class="withdraw-modal__button" [buttonData]="cancelButton" (click)="onCancel()"></app-button>
  </ng-template>
</div>
