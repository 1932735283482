"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoUnsubscribe = void 0;
function AutoUnsubscribe() {
    return function InnerFunction(targetClass, key, descriptor) {
        initializeAutoUnsubscription.call(this, targetClass);
        defineIfProperty.call(this, targetClass, key, descriptor);
        defineIfMethod.call(this, targetClass, descriptor);
    };
}
exports.AutoUnsubscribe = AutoUnsubscribe;
function initializeAutoUnsubscription(targetClass) {
    if (!targetClass.ɵUnsubscriptionHasInitialized) {
        var defaultNgOnDestroy_1 = targetClass.ngOnDestroy;
        targetClass.ɵUnsubscriptionHasInitialized = true;
        targetClass.ɵSubscriptions = new WeakMap();
        targetClass.ngOnDestroy = function () {
            var targetSubscriptions = targetClass.ɵSubscriptions.get(this);
            if (targetSubscriptions === null || targetSubscriptions === void 0 ? void 0 : targetSubscriptions.length) {
                targetSubscriptions.forEach(function (subscription, index) {
                    subscription.unsubscribe();
                    targetSubscriptions[index] = null;
                });
                targetSubscriptions.length = 0;
                targetClass.ɵSubscriptions.delete(this);
            }
            if (defaultNgOnDestroy_1 && typeof defaultNgOnDestroy_1 === 'function') {
                return defaultNgOnDestroy_1.apply(this);
            }
        };
    }
}
function defineIfProperty(targetClass, key, descriptor) {
    if (!descriptor) {
        descriptor = {
            get: function () {
                return this["\u0275" + key];
            },
            set: function (newValue) {
                if (isObservable(newValue)) {
                    defineSubscribeDefaultMethod.call(this, newValue, targetClass);
                    defineSubscribeForMethod.call(this, 'pipe', newValue);
                    defineSubscribeForMethod.call(this, 'lift', newValue);
                    defineSubscribeForMethod.call(this, 'asObservable', newValue);
                }
                else if (isSubscription(newValue)) {
                    setSubsription.call(this, targetClass, newValue);
                }
                this["\u0275" + key] = newValue;
            },
            enumerable: true,
            configurable: true,
        };
        Object.defineProperty(targetClass, key, descriptor);
    }
}
function defineIfMethod(targetClass, descriptor) {
    var originalMethod = descriptor === null || descriptor === void 0 ? void 0 : descriptor.value;
    if (originalMethod) {
        descriptor.value = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var result = originalMethod.apply(this, args);
            if (isObservable(result)) {
                defineSubscribeDefaultMethod.call(this, result, targetClass);
                defineSubscribeForMethod.call(this, 'pipe', result);
                defineSubscribeForMethod.call(this, 'lift', result);
                defineSubscribeForMethod.call(this, 'asObservable', result);
            }
            else if (isSubscription(result)) {
                setSubsription.call(this, targetClass, result);
            }
            return result;
        };
    }
}
function defineSubscribeDefaultMethod(observable, targetClass) {
    var originSubscribeMethod = observable.subscribe;
    var self = this;
    observable.subscribe = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var subscription = originSubscribeMethod.apply(this, args);
        if (!(subscription === null || subscription === void 0 ? void 0 : subscription.closed)) {
            setSubsription.call(self, targetClass, subscription);
        }
        return subscription;
    };
    return observable;
}
function defineSubscribeForMethod(methodName, observable) {
    var originMethod = observable[methodName];
    observable[methodName] =
        function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var result = originMethod.apply(this, args);
            result.subscribe = observable.subscribe;
            return result;
        };
}
function setSubsription(targetClass, subscription) {
    var targetSubscriptions = targetClass.ɵSubscriptions.get(this) || [];
    targetSubscriptions.push(subscription);
    targetClass.ɵSubscriptions.set(this, targetSubscriptions);
}
function isObservable(variable) {
    return !!variable.subscribe;
}
function isSubscription(variable) {
    return !!variable.unsubscribe;
}
