<div class="reassign-device">
  <form [formGroup]="form">
    <h3 class="reassign-device__title">Reassign Device</h3>
    <div>
      <p *ngFor="let gpu of device.systemInfo?.gpu">{{ gpu.model }} - {{ (gpu.vram / 1024).toFixed(2) }} GB</p>
    </div>

    <div class="reassign-device__field-group">
      <input id="email" placeholder="Email" formControlName="email" />
      <div class="reassign-device__error" *ngIf="form.get('email')!.getError('required') && form.get('email')!.touched">
        Email should be present
      </div>
      <div class="reassign-device__error" *ngIf="form.get('email')!.getError('email') && form.get('email')!.touched">
        Valid email address required
      </div>
    </div>

    <app-button
      class="reassign-device__button"
      [buttonData]="{ name: 'Reassign' }"
      [attr.disabled]="form.invalid"
      (click)="reassignDevice()"></app-button>
  </form>
</div>
