<table aria-describedby="transactions">
  <thead>
    <tr>
      <th>Date</th>
      <th>Amount</th>
      <th>Type</th>
      <th>Category</th>
      <th>Wallet address</th>
      <th>Confirmation</th>
    </tr>
  </thead>
  <tbody *ngIf="withdrawals.length > 0; else emptyTable">
    <tr
      *ngFor="
        let withdrawal of withdrawals
          | paginate
            : {
                itemsPerPage: this.withdrawalService.itemsPerPage,
                currentPage: this.withdrawalService.currentPage,
                totalItems: this.withdrawalService.totalItems
              }
      ">
      <td>{{ withdrawal.date + 'Z' | date : 'MMM-dd-yyyy, h:mm:ss' }}</td>
      <td>
        {{ roundUpToX(withdrawal.amount, 4) }}
        <span class="currency">{{ withdrawal.currency === 'USDC_POLYGON' ? 'USDC' : withdrawal.currency }}</span>
      </td>

      <td class="type">
<!--        <app-icon-->
<!--          [path]="withdrawal.type === 'CREDIT' ? 'dashboard/credit_arrow.svg' : 'dashboard/debit_arrow.svg'"></app-icon>-->
        <span>{{ withdrawal.type }}</span>
      </td>

      <td>{{ withdrawal.category }}</td>

      <td>
        <div *ngIf="withdrawal.walletAddress" class="wallet-address">
          <span>{{ normalizeWalletAddress(withdrawal.walletAddress) }}</span>
          <span class="icon icon-Copy-2 wallet-address__copy" (click)="onCopy(withdrawal.walletAddress)"></span>
        </div>
      </td>

      <td class="status">
        <div
          class="status-background"
          [ngStyle]="{
            'background-color': getStatusColor(withdrawal.status)
          }"
          >{{ normalizeStatus(withdrawal.status) }}</div
        >
      </td>
    </tr>
  </tbody>
  <ng-template #emptyTable>
    <tbody>
      <tr>
        <td colspan="4" class="empty-row">None</td>
      </tr>
    </tbody>
  </ng-template>
</table>
