import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ModalService } from './shared/services/modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  constructor(private modalService: ModalService, private vcr: ViewContainerRef) {
    /** Providing App Component refference for ModalSerice */
    modalService.modalParentComponentRefference = this.vcr;
  }

  ngOnInit(): void {}
}
