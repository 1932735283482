import { Component } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../../services/modal.service';
import { ButtonComponent } from '../../button/button.component';
import { NgForOf, NgIf } from '@angular/common';
import { DashboardService } from '../../../services/dashboard.service';

@Component({
  selector: 'app-reassign-device-modal',
  templateUrl: './reassign-device-modal.component.html',
  standalone: true,
  imports: [ButtonComponent, ReactiveFormsModule, NgForOf, NgIf],
  styleUrls: ['./reassign-device-modal.component.scss']
})
export class ReassignDeviceModalComponent {
  form: UntypedFormGroup;
  device;

  constructor(private modalService: ModalService, private dashboardService: DashboardService) {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });

    this.device = this.modalService.bufferData;
  }

  reassignDevice() {
    const deviceId = this.device.device.id;
    const email = this.form.value.email;
    if (this.form.valid) {
      this.dashboardService.reassignDeviceRequest(email, deviceId).subscribe({
        next: (response) => {
          if (response.success) {
            console.log('Device successfully reassigned to user');
            this.modalService.close();
          }
        }
      });
    }
  }
}
