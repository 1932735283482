import { Component, OnInit } from '@angular/core';
import { debounceTime, skip, Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { WithdrawalService } from '../../shared/services/withdrawal.service';
import { CustomCheckboxDropdownComponent } from '../../shared/components/custom-checkbox-dropdown/custom-checkbox-dropdown.component';
import { TransactionsTableComponent } from '../../shared/components/transactions-table/transactions-table.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgForOf, NgIf } from '@angular/common';
import { Transaction } from '../../shared/interfaces/withdrawal.interfaces';
import { backClicked, sortByCreatedOn } from '../../shared/utils/shared.utils';

@Component({
  selector: 'app-transactions-page',
  templateUrl: './transactions-page.component.html',
  standalone: true,
  imports: [CustomCheckboxDropdownComponent, TransactionsTableComponent, NgxPaginationModule, NgIf, NgForOf],
  styleUrls: ['./transactions-page.component.scss']
})
export class TransactionsPageComponent implements OnInit {
  withdrawals: Transaction[] = [];

  @AutoUnsubscribe()
  private withdrawalObserverSubs: Subscription | undefined;
  @AutoUnsubscribe()
  private filtersSubs: Subscription | undefined;

  constructor(public withdrawalService: WithdrawalService) {}

  ngOnInit() {
    this.withdrawalObserverSubs = this.withdrawalService.withdrawalObserver.subscribe({
      next: (response) => {
        this.withdrawals = sortByCreatedOn(response);
      }
    });

    this.filtersSubs = this.withdrawalService.selectedFilters$.pipe(skip(1), debounceTime(1250)).subscribe({
      next: (filters) => {
        this.withdrawalService.getUserWithdrawals(1, filters);
      }
    });
  }

  onCheckboxChange(value: string) {
    this.withdrawalService.handleFilterCheck(value);
  }

  pageChanged(event: any) {
    this.withdrawalService.getUserWithdrawals(event);
    this.withdrawalService.currentPage = event;
  }

  protected readonly backClicked = backClicked;
}
