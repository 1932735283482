import { RouterModule, Routes } from '@angular/router';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { NgModule } from '@angular/core';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { TransactionsPageComponent } from './pages/transactions-page/transactions-page.component';
import { DevicesPageComponent } from './pages/devices-page/devices-page.component';

const routes: Routes = [
  {
    path: '',

    children: [
      {
        path: 'dashboard',
        canActivate: [OktaAuthGuard],
        component: DashboardPageComponent
      },
      {
        path: 'dashboard/transactions',
        canActivate: [OktaAuthGuard],
        component: TransactionsPageComponent
      },
      {
        path: 'dashboard/devices',
        canActivate: [OktaAuthGuard],
        component: DevicesPageComponent
      },
      {
        path: 'login/callback',
        component: OktaCallbackComponent
      },
      { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
