import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { environment } from '../../../environments/environment';
import { GaiminResponse, TokensResponse } from '../interfaces/http.interfaces';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private idToken: string = '';

  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  set accessToken(credentials: string) {
    localStorage.setItem('accessToken', credentials);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ? localStorage.getItem('accessToken')!.toString() : '';
  }

  set refreshToken(credentials: string) {
    localStorage.setItem('refreshToken', credentials);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken')!.toString() : '';
  }

  constructor(
    private http: HttpClient,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    public oktaAuthStateService: OktaAuthStateService,
    private router: Router
  ) {
    if (this.refreshToken) {
      this.isLoggedIn$.next(true);
    } else {
      this.oktaAuthStateService.authState$.subscribe((oktaAuthState) => {
        console.log('oktaAuthState:', oktaAuthState);
        if (!!oktaAuthState.isAuthenticated) {
          this.idToken = oktaAuthState.idToken?.idToken ?? '';
          this.accessToken = oktaAuthState.accessToken!.accessToken;
          this.refreshToken = oktaAuthState.refreshToken!.refreshToken;
        }
        this.isLoggedIn$.next(!!oktaAuthState.isAuthenticated);
      });
    }

    this.oktaAuthStateService.authState$.subscribe((oktaAuthState) => {
      console.log('oktaAuthState:', oktaAuthState);
      if (!!oktaAuthState.isAuthenticated) {
        this.idToken = oktaAuthState.idToken?.idToken ?? '';
      }
    });

    this.isLoggedIn$.subscribe({
      next: (isLoggedIn) => {
        console.log('isLoggedIn', isLoggedIn);
      }
    });
  }

  authHeaders() {
    return new HttpHeaders().set('Authorization', `Bearer ${this.accessToken}`);
  }

  loginWithOkta() {
    this.oktaAuth.signInWithRedirect({ originalUri: '/' }).then((signInResult) => {
      console.log('Okta sign in result', signInResult);
    });
  }

  async logout() {
    await this.oktaAuth.isAuthenticated().then(async (isAuthenticated) => {
      console.log('isAuthenticated', isAuthenticated);
      if (isAuthenticated) {
        await this.oktaAuth.signOut();
      }
      this.clearData();
    });
  }

  clearData() {
    this.isLoggedIn$.next(false);
    this.accessToken = '';
    this.refreshToken = '';
  }

  refreshTokenRequest(): Observable<GaiminResponse<TokensResponse>> {
    const refreshToken: string = this.refreshToken;
    console.log('Make refresh token request...');
    return this.http.post<GaiminResponse<TokensResponse>>(`${environment.gaiminApi}/users/auth/refresh`, {
      refreshToken
    });
  }
}
