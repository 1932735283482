<div class="dashboard">
  <div class="dashboard__title-section">
    <h2 class="title">GME PRO</h2>

    <div>
      <app-button *ngIf="!(authService.isLoggedIn$ | async)" [buttonData]="loginBtn" (click)="login()"/>
      <app-button *ngIf="authService.isLoggedIn$ | async" [buttonData]="logoutBtn" (click)="logout()"/>
    </div>
  </div>


  <app-withdrawal-section/>

  <div>
    <div class="dashboard__header">
      <h3 class="dashboard__header-title">Information and Metrics</h3>
    </div>
    <app-metrics-monetization/>
  </div>


  <div class="dashboard__header">
    <h3 class="dashboard__header-title">My Transactions</h3>
    <div class="dashboard__header-link" *ngIf="transactionsCount > limit" (click)="openTransactionsPage()">
      <div>See more<span class="icon icon-Arrow-Right"></span></div>
    </div>
  </div>
  <div class="withdraw-filter">
    <div class="withdraw-filter__wrapper">
      <app-custom-checkbox-dropdown
        [options]="withdrawalService.filters"
        [name]="'Category'"
        (checkboxChange)="onCheckboxChange($event)"
      ></app-custom-checkbox-dropdown>
    </div>
  </div>
  <app-cancel-withdraw/>
  <app-transactions-table [withdrawals]="withdrawals"></app-transactions-table>

  <div>
    <div class="dashboard__header">
      <h3 class="dashboard__header-title">My Devices</h3>
      <div class="dashboard__header-link" *ngIf="devicesCount > limit || isSeeMoreVisible" (click)="openDevicesPage()">
        <div>See more<span class="icon icon-Arrow-Right"></span></div>
      </div>
    </div>
    <app-devices-table [devices]="devices"
                       [showHidden]="(authService.isLoggedIn$ | async) === false"></app-devices-table>
  </div>

</div>
