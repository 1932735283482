import { Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { Button } from '../../interfaces/button.interfaces';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  standalone: true,
  imports: [NgClass, NgIf],
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() buttonData: Button = {} as Button;
  @Input() disabled!: boolean;

  constructor() {}
}
