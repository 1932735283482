const CLIENT_ID = '0oa43qpy3a08Bs21x4x6';
const ISSUER = 'https://okta.gaimin.net/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = undefined;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    testing: {
      disableHttpsCheck: `${OKTA_TESTING_DISABLEHTTPSCHECK}`
    }
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages'
  }
};
