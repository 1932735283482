<div class="metrics" *ngIf="!isLoading; else skeletonLoading">
  <div class="metrics__item metrics__chart">
    <div class="metrics__chart-header">
      <span>Monetization</span>

      <!--      <span class="icon-Vector" [tooltip]="infoText" [contentType]="'html'" [options]="{-->
      <!--        maxWidth: '300px'-->
      <!--      }" [tooltipClass]="'info-tooltip'"></span>-->

      <!--      <span class="icon-Vector" [innerHTML]="infoText">?</span>-->
    </div>

    <div class="metrics__chart-body">
      <apx-chart
        [series]="chartOptions.series!"
        [chart]="chartOptions.chart!"
        [xaxis]="chartOptions.xaxis!"
        [yaxis]="chartOptions.yaxis!"
        [dataLabels]="chartOptions.dataLabels!"
        [grid]="chartOptions.grid!"
        [stroke]="chartOptions.stroke!"
        [tooltip]="chartOptions.tooltip!"
        [markers]="chartOptions.markers!"
      ></apx-chart>
    </div>
  </div>

  <div class="metrics__item metrics__info">
    <div class="metrics__info-item">
      <div *ngIf="gpu.length > 0" class="metrics__info-item-title">GPU specifications:</div>

      <ul>
        <li *ngFor="let data of gpu" style="list-style: inside;">{{ data.model }}
          - {{ (data.vram / 1024).toFixed(2) }} GB
        </li>
      </ul>
    </div>

    <div class="metrics__info-item">
      <span class="metrics__info-item_earnings"
            [title]="'Average earnings per last month'">Expected Earnings: {{ expectedEarnings }}</span>
    </div>
  </div>
</div>

<ng-template #skeletonLoading>
  <div class="metrics">
    <div class="metrics__item empty" style="width: 60%;"></div>

    <div class="metrics__item empty" style="width: 40%;"></div>
  </div>
</ng-template>
