import { Component } from '@angular/core';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { WithdrawalService } from '../../services/withdrawal.service';
import { BalanceService } from '../../services/balance.service';
import { ButtonComponent } from '../button/button.component';
import { Button } from '../../interfaces/button.interfaces';
import { WithdrawModalComponent } from '../modals/withdraw-modal/withdraw-modal.component';
import { ModalService } from '../../services/modal.service';
import { MODALS } from '../../enums/modal.enums';

@Component({
  selector: 'app-withdrawal-section',
  standalone: true,
  imports: [DecimalPipe, AsyncPipe, ButtonComponent],
  templateUrl: './withdrawal-section.component.html',
  styleUrl: './withdrawal-section.component.scss'
})
export class WithdrawalSectionComponent {
  isWithdrawDisabled: boolean = true;

  readonly button: Button = {
    icon: 'icon-Withdraw',
    name: 'Transfer'
  };

  constructor(
    public balanceService: BalanceService,
    public withdrawalService: WithdrawalService,
    private modalService: ModalService
  ) {}

  get isPending(): boolean {
    return this.withdrawalService.pendingWithdrawalData.isPending;
  }

  onClick() {
    this.modalService.create(MODALS.WITHDRAW, WithdrawModalComponent).open();
  }
}
