<div class="transactions">
  <h2 class="back-title" (click)="backClicked()">
    <span class="icon icon-Arrow-Left"></span>
    My Transactions
  </h2>
  <div class="transactions-filter">
    <div class="transactions-filter__wrapper">
      <app-custom-checkbox-dropdown
        [options]="withdrawalService.filters"
        [name]="'Category'"
        (checkboxChange)="onCheckboxChange($event)"
      ></app-custom-checkbox-dropdown>
    </div>
  </div>
  <app-transactions-table [withdrawals]="withdrawals"></app-transactions-table>
  <pagination-template
    class="pagination"
    #p="paginationApi"
    (pageChange)="pageChanged($event)"
    *ngIf="withdrawals?.length > 0">
    <div class="pagination__control" (click)="p.previous()" [class.pagination__control-disabled]="p.isFirstPage()">
      <span class="pagination__arrow-left icon-Arrow-Left"></span>
      <span>Previous</span>
    </div>
    <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
      <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
        <span class="pagination__number">{{ page.label }}</span>
      </a>
      <div *ngIf="p.getCurrent() === page.value">
        <span class="pagination__number-current">{{ page.label }}</span>
      </div>
    </div>
    <div class="pagination__control" (click)="p.next()" [class.pagination__control-disabled]="p.isLastPage()">
      <span>Next</span>
      <span class="pagination__arrow-right icon-Arrow-Right"></span>
    </div>
  </pagination-template>
</div>
