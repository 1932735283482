import { Component } from '@angular/core';
import { Button } from '../../interfaces/button.interfaces';
import { BUTTON_SIZE } from '../../enums/button.enums';
import { WithdrawalService } from '../../services/withdrawal.service';
import { ButtonComponent } from '../button/button.component';
import { NgIf } from '@angular/common';
import { ModalService } from '../../services/modal.service';
import { CancelWithdrawModalComponent } from '../modals/cancel-withdraw-modal/cancel-withdraw-modal.component';
import { MODALS } from '../../enums/modal.enums';

@Component({
  selector: 'app-cancel-withdraw',
  templateUrl: './cancel-withdraw.component.html',
  standalone: true,
  imports: [ButtonComponent, NgIf],
  styleUrls: ['./cancel-withdraw.component.scss']
})
export class CancelWithdrawComponent {
  readonly button: Button = {
    icon: 'icon-Withdraw',
    name: 'Cancel',
    size: BUTTON_SIZE.SMALL,
    classMod: 'dark'
  };

  get isPending(): boolean {
    return this.withdrawalService.pendingWithdrawalData.isPending;
  }

  get showCancelButton(): boolean {
    return this.withdrawalService.pendingWithdrawalData.status !== 'PENDING';
  }

  constructor(private modalService: ModalService, public withdrawalService: WithdrawalService) {}

  onClick() {
    this.modalService.create(MODALS.CANCEL_WITHDRAW, CancelWithdrawModalComponent).open();
  }
}
