import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { DeviceData } from '../../shared/interfaces/dashboard.interfaces';
import { DashboardService } from '../../shared/services/dashboard.service';
import { DevicesTableComponent } from '../../shared/components/devices-table/devices-table.component';
import {backClicked, sortByUSDC} from '../../shared/utils/shared.utils';

@Component({
  selector: 'app-devices-page',
  templateUrl: './devices-page.component.html',
  standalone: true,
  imports: [DevicesTableComponent],
  styleUrls: ['./devices-page.component.scss']
})
export class DevicesPageComponent implements OnInit {
  devices: DeviceData[] = [];
  @AutoUnsubscribe()
  private devicesSubs: Subscription | undefined;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit() {
    this.devicesSubs = this.dashboardService.devices$.subscribe({
      next: (response) => {
        this.devices = sortByUSDC(response);
      }
    });
  }

  protected readonly backClicked = backClicked;
}
