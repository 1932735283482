import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly refreshTokenUrl = environment.gaiminApi + '/users/auth/refresh';
  private isRefreshing: boolean = false;

  constructor(private readonly authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body != null && !event.body.success) {
          if (event.url?.includes(this.refreshTokenUrl) && event.body.error?.type === 'AUTHORIZATION') {
            this.logoutProcess();
          }
          if (event.body.error?.type === 'AUTHENTICATION' && !request.url.includes('roles')) {
            this.handle401Error();
          }
        }
      })
    );
  }

  private handle401Error() {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      const refreshToken = this.authService.refreshToken;

      if (refreshToken) {
        return this.authService
          .refreshTokenRequest()
          .pipe(
            tap((response) => {
              this.isRefreshing = false;
              this.authService.accessToken = response.data!.accessToken;
              this.authService.refreshToken = response.data!.refreshToken;
              this.authService.isLoggedIn$.next(true);
              window.location.reload();
            }),
            catchError((error) => {
              this.isRefreshing = false;
              this.logoutProcess();
              return throwError(() => error);
            })
          )
          .subscribe();
      } else {
        console.log('Refresh token doesn`t exist');
        this.logoutProcess();
        return EMPTY;
      }
    }
    return EMPTY;
  }

  private logoutProcess() {
    this.authService.logout();
  }
}
