<table class="table" aria-describedby="devices" *ngIf="!isAllDevicesHidden(devices) || showHidden">
  <thead>
    <tr>
      <th *ngIf="isLoggedIn" class="table__empty"></th>
      <th>OS</th>
      <th class="table__processor">GPU</th>
      <th class="table__processor">CPU</th>
      <th class="table__date">Installation</th>
      <th class="table__date">Last Activity</th>
      <th class="table__reward">Reward</th>
    </tr>
  </thead>
  <tbody *ngIf="devices.length > 0; else emptyTable">
    <tr
      *ngFor="let device of devices; let i = index"
      [ngClass]="{ table__current_device: device.device.id === currentDevice?.deviceId && devices.length > 1 }"
      title=" {{ device.device.id === currentDevice?.deviceId ? 'Active device' : 'Information about device' }}">
      <td
        *ngIf="isLoggedIn"
        class="hide-row"
        [title]="device.device.hidden ? 'Show on Dashboard' : 'Hide from Dashboard'"
        (click)="handleDeviceVisible(device)">
        <span class="icon" [ngClass]="device.device.hidden ? 'icon-Eye' : 'icon-Closed-Eye'"></span>
      </td>
      <td>{{ device.systemInfo?.os.distro }}</td>
      <td>
        <ul>
          <li *ngFor="let gpu of device.systemInfo?.gpu">{{ gpu.model }} - {{ (gpu.vram / 1024).toFixed(2) }} GB</li>
        </ul>
      </td>
      <td>
        {{ device.systemInfo?.cpu.manufacturer }} {{ device.systemInfo?.cpu.brand }}
        {{ device.systemInfo?.cpu.speed ? ', ' + device.systemInfo.cpu.speed + ' GHz' : '' }}
      </td>
      <td>{{ device.systemInfo?.createdOn | date : 'MM.dd.yyyy' }}</td>
      <td>{{ device.lastOnline | date : 'MM.dd.yyyy' }}</td>
      <td>{{ device.balance?.paid.GMRX | number : '1.5-5' }} GMRX</td>
      <td class="reassign-row" *ngIf="isLoggedIn">
        <app-button
          class="table__reassign-button"
          [buttonData]="{ name: 'Reassign', classMod: 'secondary', size: 'small' }"
          (click)="openReassignModal(device)"></app-button>
      </td>
    </tr>
  </tbody>
  <ng-template #emptyTable>
    <tbody>
      <tr>
        <td colspan="7" class="empty-row">None</td>
      </tr>
    </tbody>
  </ng-template>
</table>

