import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subscription, filter } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[appClickedOutside]'
})
export class ClickedOutsideDirective implements AfterViewInit, OnDestroy {
  @Output() clickOutside = new EventEmitter<void>();
  documentClickSubscription: Subscription | undefined;

  constructor(
    private element: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngAfterViewInit() {
    this.documentClickSubscription = fromEvent(this.document, 'click')
      .pipe(
        filter((event: any) => {
          return !this.isInside(event.target as HTMLElement);
        })
      )
      .subscribe({
        next: () => {
          this.clickOutside.emit();
        }
      });
  }

  isInside(elementToCheck: HTMLElement): boolean {
    return elementToCheck === this.element.nativeElement || this.element.nativeElement.contains(elementToCheck);
  }

  ngOnDestroy() {
    this.documentClickSubscription?.unsubscribe();
  }
}
