import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CheckboxOption } from '../../interfaces/dashboard.interfaces';
import { CommonModule } from '@angular/common';
import { ClickedOutsideDirective } from '../../directives/clicked-outside.directive';

@Component({
  selector: 'app-custom-checkbox-dropdown',
  standalone: true,
  imports: [CommonModule, ClickedOutsideDirective],
  templateUrl: './custom-checkbox-dropdown.component.html',
  styleUrls: ['./custom-checkbox-dropdown.component.scss']
})
export class CustomCheckboxDropdownComponent implements OnChanges {
  @Input() options: CheckboxOption[] = [];
  @Input() name: string = '';

  @Output() private checkboxChange: EventEmitter<any> = new EventEmitter<any>();

  isDropdownOpen: boolean = false;
  isSelected: boolean = false;

  constructor() {}

  // Calls when "options" change
  ngOnChanges() {
    this.isSelected = this.options.some((item) => item.isChecked);
  }

  closeDropdown() {
    this.isDropdownOpen = false;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onCheckboxChange(event: any) {
    const value = event.target['value'];
    this.checkboxChange.emit(value);
  }
}
